import Box from '@material-ui/core/Box';
import CategoryCard from './category-card';
import React from 'react';
import {HeroCarousel} from '@proxibid/proxiponents';

function PropertyCategoryCarousel() {
    return (
        <Box mb={3}>
            <HeroCarousel title='Personal Property and Real Estate'>
                <CategoryCard categoryId={8}/>
                <CategoryCard categoryId={14}/>
                <CategoryCard categoryId={4}/>
                <CategoryCard categoryId={13}/>
                <CategoryCard categoryId={7}/>
                <CategoryCard categoryId={0}/>
            </HeroCarousel>
        </Box>
    );
}

export default PropertyCategoryCarousel;