import Box from '@material-ui/core/Box';
import CategoryCard from './category-card';
import React from 'react';
import {HeroCarousel} from '@proxibid/proxiponents';

function CollectibleCategoryCarousel() {
    return (
        <Box mb={3}>
            <HeroCarousel title='Art, Antiques, and Collectibles'>
                <CategoryCard categoryId={1}/>
                <CategoryCard categoryId={9}/>
                <CategoryCard categoryId={15}/>
                <CategoryCard categoryId={5}/>
                <CategoryCard categoryId={0}/>
            </HeroCarousel>
        </Box>
    );
}

export default CollectibleCategoryCarousel;
