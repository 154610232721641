import Box from '@material-ui/core/Box';
import React from 'react';
import liveAuctionsImage from './images/live-auctions3.jpg';
import timedEventsImage from './images/timed-events.jpg';
import todaysEventsImage from './images/todaysEventsIcon.jpg';
import auctionsNearMeImage from './images/auctions-near-me2.jpg';
import {HeroCard, HeroGrid} from '@proxibid/proxiponents';

function BrowseByCards() {
    return (
        <Box mb={3}>
            <HeroGrid title='Browse By'>
                <HeroCard title={'Live Auctions'} imageUrl={liveAuctionsImage} url={'/live-auctions'}/>
                <HeroCard title={'Timed Auctions'} imageUrl={timedEventsImage} url={'/timed-events'}/>
                <HeroCard title={'Today\'s Events'} imageUrl={todaysEventsImage} url={'/todays-auctions'}/>
                <HeroCard title={'Auctions Near Me'} imageUrl={auctionsNearMeImage} url={'/auctions-near-me-map'}/>
            </HeroGrid>
        </Box>
    );
}

export default BrowseByCards;