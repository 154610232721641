import Box from '@material-ui/core/Box';
import CategoryCard from './category-card';
import React from 'react';
import {HeroCarousel} from '@proxibid/proxiponents';

function HeavyCategoryCarousel() {
    return (
        <Box mb={3}>
            <HeroCarousel title='Machinery and Equipment'>
                <CategoryCard categoryId={11}/>
                <CategoryCard categoryId={3}/>
                <CategoryCard categoryId={16}/>
                <CategoryCard categoryId={2}/>
                <CategoryCard categoryId={6}/>
                <CategoryCard categoryId={0}/>
            </HeroCarousel>
        </Box>
    );
}

export default HeavyCategoryCarousel;