import BootstrapUtil from '../bootstrap-util';
import BrowseByCards from '../js/category/browse-by-cards';
import CollectibleCategoryCarousel from '../js/category/collectibles-category-carousel';
import PropertyCategoryCarousel from '../js/category/property-category-carousel';
import VehiclesCategoryCarousel from '../js/category/vehicles-category-carousel';
import HeavyCategoryCarousel from '../js/category/heavy-category-carousel';

const reactComponentMap = {
    BrowseByCards,
    CollectibleCategoryCarousel,
    PropertyCategoryCarousel,
    VehiclesCategoryCarousel,
    HeavyCategoryCarousel
};

BootstrapUtil.bootstrapEntrypoint(reactComponentMap);