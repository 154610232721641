import React from 'react';
import * as PropTypes from 'prop-types';
import farmImage from './images/farm-machinery-implements.png';
import benefitsImage from './images/benefit-charity.png';
import artImage from './images/art-antiques-collectibles.png';
import coinImage from './images/coin-currency.png';
import collectorCarImage from './images/collector-cars.png';
import commercialTruckImage from './images/commercial-trucks.png';
import computersElectronicsImage from './images/computers-electronics.png';
import horseImage from './images/horse-livestock.png';
import estateImage from './images/estate-personal-property.png';
import gunsImage from './images/guns-military-artifacts.png';
import heavyConstructionEquipmentImage from './images/heavy-construction-equipment.png';
import industrialMachineryImage from './images/industrial-machinery-equipment.png';
import jewelryImage from './images/jewelry-gemstones-watches.png';
import realEstateImage from './images/real-estate.png';
import carsVehiclesImage from './images/cars-vehicles.png';
import moreCategoriesImage from './images/more-categories.svg';
import {HeroCard} from '@proxibid/proxiponents';

CategoryCard.propTypes = {
    categoryId: PropTypes.number.isRequired,
};


const categoryDataMap = {
    0: {title: 'More Categories', image: moreCategoriesImage, url: '/all-categories'},
    1: {title: 'Art, Antiques & Collectibles', image: artImage, url: '/for-sale/art-antiques-collectibles'},
    2: {
        title: 'Industrial Machinery & Equipment',
        image: industrialMachineryImage,
        url: '/for-sale/industrial-machinery-equipment'
    },
    3: {
        title: 'Heavy Construction Equipment',
        image: heavyConstructionEquipmentImage,
        url: '/for-sale/heavy-construction-equipment'
    },
    4: {title: 'Real Estate', image: realEstateImage, url: '/for-sale/real-estate'},
    5: {title: 'Benefit & Charity', image: benefitsImage, url: '/for-sale/benefit-charity'},
    6: {title: 'Cars & Vehicles', image: carsVehiclesImage, url: '/for-sale/cars-vehicles'},
    7: {title: 'Horse & Livestock', image: horseImage, url: '/for-sale/horse-livestock'},
    8: {title: 'Estate & Personal Property', image: estateImage, url: '/for-sale/estate-personal-property'},
    9: {title: 'Coins & Currency', image: coinImage, url: '/for-sale/coins-currency'},
    10: {title: 'Guns & Military Artifacts', image: gunsImage, url: '/for-sale/guns-military-artifacts'},
    11: {title: 'Farm Equipment & Machinery', image: farmImage, url: '/for-sale/farm-equipment-machinery'},
    13: {title: 'Computers & Electronics', image: computersElectronicsImage, url: '/for-sale/computers-electronics'},
    14: {title: 'Jewelry, Gemstones & Watches', image: jewelryImage, url: '/for-sale/jewelry-gemstones-watches'},
    15: {title: 'Collector Cars', image: collectorCarImage, url: '/for-sale/collector-cars'},
    16: {title: 'Commercial Trucks', image: commercialTruckImage, url: '/for-sale/commercial-trucks'},
};

function CategoryCard(props) {
    const {categoryId} = props
    const {title, image, url} = categoryDataMap[categoryId];

    return (
        <HeroCard title={title} imageUrl={image} url={url}/>
    );
}

export default CategoryCard;
